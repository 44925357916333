import { useEffect, useState } from 'react';
import { NextSeo, NextSeoProps } from 'next-seo';
import { OpenGraphMedia } from 'next-seo/lib/types';
import { Picture, PictureType } from 'generated/graphql';
import { cloudinaryLoaderSquare, cloudinaryLoaderWide } from 'src/utilities/image-sdk';

/**
 * Helper to convert Picture objects to OpenGraphMedia objects.
 * Makes sure we never return images that are too big via the cloudinary API.
 */
function convertPictureToOpenGraphMedia(picture: Picture): OpenGraphMedia {
  const { type, url } = picture;
  const mediaSize = 512;

  if (type === PictureType.Cover || type === PictureType.TeaserSquare) {
    return {
      url: cloudinaryLoaderSquare({ src: url, width: mediaSize }),
      width: mediaSize,
      height: mediaSize,
    };
  }

  if (type === PictureType.Teaser) {
    return {
      url: cloudinaryLoaderWide({ src: url, width: mediaSize }),
      width: mediaSize,
      height: mediaSize / (16 / 9),
    };
  }

  throw new Error(`Unsupported picture type: ${type}`);
}

type HeadTagsProps = NextSeoProps & {
  picture?: Picture;
};
/**
 * React component for generating SEO and meta tags for a given page.
 * Extends the default NextSeo component by passing `title` and `description` to the `openGraph` prop.
 * This component will override the values specified in the `<DefaultHeadTags />` component.
 *
 * @example
 * <Page testId="home-page">
 *   <HeadTags
 *     title="Home"
 *     description="Welcome to my website"
 *     openGraph={{ type: 'website' }}
 *   />
 * </Page>
 */
export default function HeadTags({ title, description, picture, openGraph, canonical, ...props }: HeadTagsProps) {
  const [isMounted, setIsMounted] = useState(false);
  // Convert the picture to an OpenGraphMedia object
  const image = picture && convertPictureToOpenGraphMedia(picture);

  /**
   * Trigger a manual re-render after the initial render to force the NextSeo component to re-render AFTER the <DefaultHeadTags /> component.
   * Without this, it is possible that the default SEO title is rendered even when this component is used to override it.
   * This is probably a bug on our side, but since NextJS 13 app directory has its own page metadata interface it might not be worth spending time to fix it and this workaround is good enough.
   *
   * @todo [next@>=15] Use the page metadata interface that Next 14 provides and remove NextSeo
   */
  useEffect(() => {
    if (!isMounted) setIsMounted(true);
  }, [isMounted]);

  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        title,
        description,
        images: image ? [image] : undefined,
        ...openGraph,
      }}
      canonical={canonical}
      {...props}
    />
  );
}
