import type { NextRequest, NextResponse } from 'next/server';
import Cookies from 'universal-cookie';

/**
 * Read This First:
 * This file contains the logic for managing A/B tests in the app. 
 * It is a temporary solution until we have a proper A/B testing framework in place.
 * The A/B tests are used to test different variations of the app on the users.
 * The user is enrolled in a test by assigning a random variant to them.
 * The user's enrollment is stored in a cookie.
 * The user can be enrolled in multiple tests at the same time, the tests can be added or removed at any time.
 * 
 * To add a new A/B test, just add a new object to the `activeAbTests` array.
 * @example
 * const activeAbTests = [
      {
        name: 'xp2024-09-t1',
        totalVariants: 2,
        description: 'Test New Hero Header on the Homepage',
      },
    ] as const satisfies AbTest[];

  If the experiment is removed from the list, 
  the users that were enrolled in it will have the cookie reset to an empty value.
  * 
  * If you need to render a different UI based on the user's enrollment in the test,
  * you can use the `isInAbTest` function in the component.
  * @example 
  * isInAbTest('xp2024-09-t1') => 'v1' (original variant)
  * isInAbTest('xp2024-09-t1') => 'v2' (variant 2) render ann alternative UI etc.
  * 
  * if you want to see how a different variant of the test looks like,
  * you can modify the 'stage-ab' cookie in the browser's dev tools, 
  * e.g. by changing 'xp2024-09-t1:v1' to 'xp2024-09-t1:v2'
  * 
  * You don't need to do anything else to track the user's enrollment in the test. 
  * It is done automatically in the `useUserTracking` hook.
 */

/**
 * A/B test type
 * @param name - the name of the test
 * @param totalVariants - the number of total variants in the test
 * @param description - the description of the test
 */
export type AbTest = {
  name: string;
  totalVariants: number;
  description: string;
};

/**
 * A/B test variant type
 * @example 'v1', 'v2'
 */
type AbTestVariant = `v${number}`;

/**
 * A/B test enrollment type
 * @param name - the name of the test
 * @param variant - the variant of the test
 * @example { name: 'xp2024-05-t1', variant: 'v1' }
 */
type AbTestEnrollment = { name: string; variant: AbTestVariant };

// the list of currently active ab tests
// if a test is removed from the list,
// the users that were enrolled in it will the cookie reset to empty value
export const activeAbTests = [
  {
    name: 'xp2024-10-t2',
    totalVariants: 2,
    description: 'Test Variations of Plans Copy, Edition 3',
  },
] as const satisfies AbTest[];

// extract names of the active tests into a separate type
type ActiveAbTestNames = (typeof activeAbTests)[number]['name'];

// the name of the cookie that stores the info about the enrolled tests
const abTestsCookieName = 'stage-ab';
// how long the cookie should live once it is set
const cookieMaxLifetimeDays = 30;

// check if the app is in mock mode - running e2e tests
const isMocked = process.env.NEXT_PUBLIC_MOCK_API === 'true';

/**
 * Check if the user agent is a bot
 * @param userAgent - the user agent string
 */
function isUserAgentBot(userAgent?: string | null): boolean {
  return /bot|googlebot|crawler|spider|robot|crawling|checkly/i.test(userAgent || '');
}

/**
 * Assign a variation number in a specific AB Test
 * @param totalVariations  - a number of total variations in the test
 */
function assignVariation(totalVariations: number) {
  return Math.floor(Math.random() * totalVariations + 1);
}

/**
 * Convert the list of tests to a string that can be stored in a cookie
 * @param tests - an array of active AB tests
 * @returns {string} - e.g. 'xp2024-05-t1:v1|xp2024-06-t2:v3'
 */
function testsToCookieValue(tests: AbTest[], testsEnrolled: AbTestEnrollment[] = []) {
  // extract the names of the tests that the user is already enrolled in
  const testsAlreadyEnrolled = testsEnrolled.map((x) => `${x.name}:${x.variant}`);
  // stringified list of tests that the user should be enrolled into
  const testsToEnrollInto = tests
    // filter out the tests that the user already has
    .filter((x) => !testsEnrolled.some((y) => y.name === x.name))
    // assign a random variation to the user in the remaining active tests
    .map((x) => `${x.name}:v${assignVariation(x.totalVariants)}`);
  // return the combined list of all tests
  return [...testsAlreadyEnrolled, ...testsToEnrollInto].join('|');
}

/**
 * Manage the AB-test cookies on the page requests in the middleware
 * Enroll the user in one of the variants of the tests that are currently active
 * Maintain the existing enrollment
 * Ignore the AB-test cookie if the user is running e2e tests
 */
export function withAbTestCookie(request: NextRequest, response: NextResponse, allTests: AbTest[]) {
  // if the app is mocked and running in E2E test mode, ignore the AB-test cookie
  if (isMocked) {
    return response;
  }
  // check if the user agent is a Google or Checkly bot
  const isBot = isUserAgentBot(request.headers.get('user-agent'));
  // if the user is a bot, ignore the AB-test cookie
  if (isBot) {
    return response;
  }
  // check if user already has an AB-test cookie
  const currentCookieValue = request.cookies.get(abTestsCookieName)?.value;
  // convert the current ab cookie value to an array of test enrollments
  // e.g. [] or ['xp2024-05-t1:v1', 'xp2024-06-t2:v3']
  // this is also makes sure to clean up the cookie from the expired tests
  // eslint-disable-next-line unicorn/no-array-reduce
  const testsEnrolled: AbTestEnrollment[] = (currentCookieValue?.split('|') || []).reduce<AbTestEnrollment[]>(
    (accumulator, test) => {
      const enrollment = parseEnrollment(test);
      // check if the enrollment is valid
      // and if the previously enrolled test is still active
      if (enrollment && allTests.some((x) => x.name === enrollment.name)) {
        accumulator.push(enrollment);
      }
      return accumulator;
    },
    [],
  );

  // store the updated cookie for the given amount of days
  response.cookies.set(abTestsCookieName, testsToCookieValue(allTests, testsEnrolled), {
    path: '/',
    maxAge: 1000 * 60 * 60 * 24 * cookieMaxLifetimeDays,
  });
  return response;
}

function getAbTestCookieValue() {
  const cookies = new Cookies();
  // Get A/B tests info from the cookie
  const cookieValue = cookies.get<string | undefined>(abTestsCookieName);
  return cookieValue;
}

/**
 *  a helper function to parse a string to a A-B test enrollment object
 * @param enrollment - a string that represents the enrollment in the test
 * @returns {AbTestEnrollment} - the parsed enrollment object
 * @returns {undefined} - if the enrollment is invalid
 * @example parseEnrollment('xp2024-05-t1:v1') => { name: 'xp2024-05-t1', variant: 'v1' }
 * @example parseEnrollment('xp2024-05-t1') => undefined
 */
function parseEnrollment(enrollment: string): AbTestEnrollment | undefined {
  // split the test into the name and the variant, e.g. 'xp2024-05-t1:v1' => ['xp2024-05-t1', 'v1']
  const [name, variantValue] = enrollment.split(':');
  // check if the variant is valid, e.g. 'v1' or 'v2'
  const variant = validatedVariant(variantValue);
  if (name && variant) {
    return { name, variant } satisfies AbTestEnrollment;
  }
  return undefined;
}

/**
 * a helper function to check if a string is a valid variant, e.g. 'v1' or 'v2'
 * @param variant - a string that represents the variant
 * @returns {AbTestVariant} - the parsed variant
 * @returns {undefined} - if the variant is invalid
 * @example validatedVariant('v1') => 'v1'
 * @example validatedVariant('as') => undefined
 */
function validatedVariant(variant?: string): AbTestVariant | undefined {
  return variant && /^v\d+/.test(variant) ? (variant as AbTestVariant) : undefined;
}

/**
 * Get the list of all A-B tests that the current user is rolled-in
 * This method is used on the client
 */
export function getUsersAbTests(cookieValue = getAbTestCookieValue()): AbTestEnrollment[] | undefined {
  // the expected cookieValue is something like `xp2024-05-t1:v1|xp2024-06-t2:v3`
  const validEnrollments: AbTestEnrollment[] | undefined = cookieValue
    ?.split('|')
    ?.map(parseEnrollment)
    // filter out the invalid entries
    .filter((item) => item !== undefined);

  return validEnrollments;
}

/**
 * Check if the user is enrolled in a particular experiment,
 * e.g. isInAbTest('experimentY') => 'v1' or isInAbTest('experimentX') => undefined
 */
export function isInAbTest(testName: ActiveAbTestNames) {
  const usersAbTests = getUsersAbTests();
  return usersAbTests?.find((test) => test.name === testName)?.variant;
}
