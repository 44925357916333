import { ParsedUrlQuery } from 'node:querystring';
import {
  Album,
  Artist,
  Epoch,
  Genre,
  Group,
  LiveConcert,
  Partner,
  PerformanceWork,
  ListSlider,
  SubscriptionPlanInterval,
  Track,
  Video,
  VodConcert,
} from 'generated/graphql';
import { SupportedLocale, TrailerVideo } from 'src/types';
import { defaultLocale } from 'src/utilities/i18n-helpers';

type PartialPerformanceWorkNode = Pick<PerformanceWork, '__typename' | 'id'> & {
  vodConcert: Pick<VodConcert, 'id'>;
};

type PartialTrackWithAlbumNode = Pick<Track, '__typename' | 'id'> & {
  album: Pick<Album, 'id'>;
};

/** List all the node types that might have an addressable URL, e.g. when sharing */
export type LinkableNode =
  | Pick<
      Album | Artist | Epoch | Genre | Group | LiveConcert | Partner | ListSlider | Video | VodConcert | TrailerVideo,
      '__typename' | 'id'
    >
  | PartialPerformanceWorkNode
  | PartialTrackWithAlbumNode;

/**
 * Exports a helper function that takes a GraphQL node and returns its Next.js page path
 * Note: performance works and album tracks have no paths, they will return a video or album path respectively
 * @example
 * ```ts
 * const node: Node = {
 *   __typename: 'Album',
 *   id: '123'
 * }
 * const path = getPagePath(node);
 * // path is now '/albums/123'
 * ```
 */
export function getNodePath(node: LinkableNode): string {
  switch (node.__typename) {
    case 'Album': {
      return `/audio/${node.id}`;
    }
    case 'Track': {
      return `/audio/${node.album.id}`;
    }
    case 'Artist': {
      return `/artist/${node.id}`;
    }
    case 'Epoch': {
      return `/epoch/${node.id}`;
    }
    case 'Group': {
      return `/group/${node.id}`;
    }
    case 'Genre': {
      return `/genre/${node.id}`;
    }
    case 'LiveConcert':
    case 'Video':
    case 'VodConcert':
    case 'Trailer': {
      return `/video/${node.id}`;
    }
    case 'PerformanceWork': {
      return `/video/${node.vodConcert.id}`;
    }
    case 'Partner': {
      return `/partner/${node.id}`;
    }
    case 'ListSlider': {
      return `/search/browse/${node.id}`;
    }
  }
}

type PlayableNode =
  | Pick<Album | LiveConcert | Video | VodConcert | TrailerVideo, '__typename' | 'id'>
  | PartialPerformanceWorkNode
  | PartialTrackWithAlbumNode;

/**
 * Exports a helper function that takes a GraphQL node and returns its player page path
 *
 * @example
 * ```ts
 * const node: Node = {
 *   __typename: 'Album',
 *   id: '123'
 * }
 * const path = getPlayerPath(node);
 * // path is now '/albums/123?player=true'
 * ```
 */
export function getPlayerPath(node: PlayableNode, trackId?: string | null, timeSeconds?: number): string {
  const pathname = getNodePath(node);
  const params = new URLSearchParams();
  params.set('player', 'true');
  // in some cases the track id is included in the node
  const trackIdString = 'vodConcert' in node ? node.id : trackId;
  if (trackIdString) {
    params.set('trackId', trackIdString);
  }
  if (timeSeconds !== undefined && timeSeconds >= 0) {
    params.set('t', timeSeconds.toString());
  }
  if (node.__typename === 'Trailer') {
    params.set('trailer', 'true');
  }
  return `${pathname}${pathname.includes('?') ? '&' : '?'}${params.toString()}`;
}

export const originHost = process.env.NEXT_PUBLIC_HOST || 'unresolved-host-env';

/**
 * Generate absolute URL for a given path with an optional locale
 *
 * @example
 * ```ts
 * getAbsoluteUrl('/foo/bar') // => https://www.example.com/foo/bar
 * getAbsoluteUrl('/foo/bar', 'en') // => https://www.example.com/foo/bar
 * getAbsoluteUrl('/foo/bar', 'de') // => https://www.example.com/de/foo/bar
 * ```
 */
export function getAbsoluteUrl(path: string, locale: SupportedLocale = defaultLocale): string {
  // if the path is absolute already, ignore the rest
  if (/^(https|\/\/):/.test(path)) {
    return path;
  }
  // if the path does not start with a slash, add one
  if (!path.startsWith('/')) {
    path = `/${path}`;
  }
  return `${originHost}${localizePath(path, locale)}`;
}

/**
 * A helper component to generate a plan payment url
 * including the necessary query params like redirect
 * @param interval
 *
 *  * @example
 * ```ts
 * getPlanUrl('YEARLY') // => /account/payment?plan=yearly
 * getPlanUrl('MONTHLY', '/audio/album_85M64TBDBSOJEC8') // => /account/payment?plan=monthly&redirect=%2Faudio%2Falbum_85M64TBDBSOJEC8
 * ```
 */
export function getPlanUrl(interval: SubscriptionPlanInterval, returnPage?: string): string {
  const planPaymentUrl = `/account/payment?plan=${interval.toLowerCase()}`;
  // on server side return the url to the plan payment page without any redirects
  if (typeof window === 'undefined' || !returnPage) {
    return planPaymentUrl;
  }
  // construct the payment URL with return redirect params
  return `${planPaymentUrl}${returnPage ? `&redirect=${encodeURIComponent(returnPage)}` : ''}`;
}

/**
 * A helper function to check if the URL is a player Url
 * @param url the url to match against, e.f. `/audio/album_85M64TBDBSOJEC8?player=true`
 * @returns boolean true if the url is a player url
 */
export function isPlayerUrl(url: string): boolean {
  return url.includes('player=true');
}

/**
 * A helper function to check if the URL is a Trailer player Url
 * @param url the url to match against, e.f. `/audio/album_85M64TBDBSOJEC8?player=true&trailer=true`
 * @returns boolean true if the url is a trailer player url
 */
export function isTrailerPlayerUrl(url: string): boolean {
  return isPlayerUrl(url) && url.includes('trailer=true');
}

/**
 * A helper to check if the current page is on the main production host
 */
export function isProductionHost(url?: URL): boolean {
  return url?.hostname === 'www.stage-plus.com';
}

/**
 * A helper function that extracts the track id from a URL
 * @param url the url to match against, e.f. `/audio/album_85M64TBDBSOJEC8?player=true&trackId=track_AHP62ORBBSSJ4DHN`
 * @returns string .e.g. `track_AHP62ORBBSSJ4DHN` or undefined if no match
 */
export function getTrackFromUrl(url: string): string | undefined {
  return url.match(/trackId=(\w+)/)?.[1];
}

/** a helper to extract the time seconds from the url params
 * @example
 * ```ts
 * const time = getTimeFromUrlParams(window.location.search);
 * // time is now the time in seconds
 * ```
 * @param searchQuery - the url params, e.g. `id=abc&t=123`
 **/
export const getTimeFromUrl = (searchQuery: string): number | undefined => {
  const timeString = new URLSearchParams(searchQuery).get('t');
  return timeString ? Number.parseInt(timeString, 10) : undefined;
};

/**
 * Localizes the given path to the given locale. Strips the locale from the path if it's the default locale.
 * @param requestUrl The path to localize
 * @param locale The locale to localize to
 * @returns The localized path
 *
 * * @example
 * ```ts
 * localizePath('/discover', 'de') // '/de/discover'
 * localizePath('/discover', 'en') // '/discover'
 * ```
 */
export function localizePath(requestUrl: string, locale: SupportedLocale): string {
  // If the locale is the default locale, it does not need to be added to the path
  return locale === defaultLocale ? requestUrl : `/${locale}${requestUrl.replace(/\/$/, '')}`;
}

/**
 * A helper function that extracts the important query params from a URL
 * that might need to be carried over to other pages
 */
export function transferImportantQueryParameters(query: ParsedUrlQuery): ParsedUrlQuery {
  return {
    // always transfer the redirect param
    ...(query.redirect && { redirect: query.redirect }),
    // always transfer the limited navigation parameter
    ...(query.hideNav && { hideNav: query.hideNav }),
  };
}
