import type { SVGProps } from 'react';
const SvgLanguage = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m15.457 8.166-.327.83-1.456 3.672-.714 1.803L11.166 19h2.218l.91-2.46h4.482l.926 2.46H22L17.707 8.166zm-.185 5.752-.292.786h3.127l-1.564-4.187zm-1.364-6.365q.05-.128.119-.241h-4.003V4H8.156v3.312H2.675v1.684h4.717c-1.205 2.24-3.203 4.397-5.393 5.54.412.356 1.014 1.012 1.308 1.455 1.88-1.142 3.571-2.955 4.85-5.025v6.367h1.867v-6.438c.5.795 1.06 1.556 1.665 2.258l.71-1.791a14.3 14.3 0 0 1-1.671-2.366h2.609z"
    />
  </svg>
);
export default SvgLanguage;
